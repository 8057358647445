import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';

const MenuItem = ({ item }) => {
  // 그룹 타입이면 자식 아이템을 렌더링
  if (item.type === 'group') {
    return (
      <>
        <ListItem>
          <ListItemText primary={item.title} />
        </ListItem>
        {/* 그룹의 자식 요소들을 재귀적으로 렌더링 */}
        {item.children && item.children.map((child) => <MenuItem key={child.id} item={child} />)}
      </>
    );
  }

  // 기본 아이템 렌더링 (item 타입)
  return (
    <ListItem button component={Link} to={item.url}>
      <ListItemIcon>
        {/* 아이콘이 있는 경우에만 렌더링 */}
        {item.icon && <item.icon />}
      </ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

export default MenuItem;
