// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { CookiesProvider } from 'react-cookie';
import { CookiesProvider as CustomCookiesProvider } from 'utils/context/CookiesContext';

// ==============================|| APP - THEME, ROUTER, LOCAL   ||============================== //

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <CookiesProvider>
        <CustomCookiesProvider>
          <Routes />
        </CustomCookiesProvider>
      </CookiesProvider>
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
