// assets
import { SolutionOutlined, UserOutlined, ProfileOutlined } from '@ant-design/icons';
// icons
const icons = {
  SolutionOutlined,
  UserOutlined,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const referee = {
  id: 'referee',
  title: '심판',
  type: 'group',
  children: [
    {
      id: 'record',
      title: '기록',
      type: 'item',
      url: '/record/search',
      icon: icons.SolutionOutlined,
      allowedAuth: [0, 1, 2],
      breadcrumbs: false
    },
    {
      id: 'players',
      title: '선수명단',
      type: 'item',
      url: '/players',
      icon: icons.UserOutlined,
      allowedAuth: [0, 1, 2],
      breadcrumbs: false
    },
    {
      id: 'score',
      title: '기록&순위',
      type: 'item',
      url: '/scores',
      icon: icons.ProfileOutlined,
      allowedAuth: [0, 1, 2],
      breadcrumbs: false
    }
  ]
};

export default referee;
