import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useCookiesContext } from 'utils/context/CookiesContext';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';

const HeaderContent = () => {
  // useCookiesContext 훅을 사용하여 전역 쿠키 상태에서 refereeToken을 가져옵니다.
  const { refereeToken } = useCookiesContext();
  const [competitionName, setCompetitionName] = useState('');

  useEffect(() => {
    // refereeToken이 존재하고 competitionName 속성이 있을 경우, 상태를 업데이트합니다.
    if (refereeToken && refereeToken.competitonName) {
      setCompetitionName(refereeToken.competitonName);
    }
  }, [refereeToken]); // refereeToken을 의존성 배열에 추가합니다.

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      {/* 중앙 요소 - 대회명 */}
      <Typography
        variant="h4"
        component="div"
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          fontSize: {
            xs: '0.8rem', // 모바일
            sm: '1rem', // 작은 태블릿
            md: '1.3rem', // 큰 태블릿
            lg: '1.3rem', // 작은 데스크탑
            xl: '1.5rem' // 큰 데스크탑
          }
        }}
      >
        {competitionName}
      </Typography>

      {/* 오른쪽 요소 - Profile 또는 MobileSection */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Box>
  );
};

export default HeaderContent;
