// project import
import dashboard from './dashboard';
import manager from './manager';
import referee from './referee';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, referee, manager]
};

export default menuItems;
