import { jwtDecode } from 'jwt-decode'; // named export로 수정

// JWT 토큰을 디코드하는 함수
export const decodeToken = (token) => {
  try {
    const decoded = jwtDecode(token); // named export로 사용
    return decoded;
  } catch (error) {
    console.error('Invalid token:', error);
    return null;
  }
};

// JWT 토큰의 만료 여부를 확인하는 함수
export const isTokenExpired = (token) => {
  const decoded = decodeToken(token);
  if (!decoded || !decoded.exp) return true; // 만료 여부 확인

  const currentTime = Date.now() / 1000; // 현재 시간(초 단위)
  return decoded.exp < currentTime; // 토큰이 만료되었는지 확인
};
