import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export const Authmiddleware = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies(['jwtToken', 'refereeName']);

  useEffect(() => {
    const token = cookies?.jwtToken;
    const refereeName = cookies?.refereeName;
    const path = location.pathname;

    //console.log('refereeName:', refereeName);

    if (
      !token &&
      !refereeName &&
      path !== '/login' &&
      path !== '/register' &&
      path !== '/competition-register' &&
      !path.startsWith('/competition-settings')
    ) {
      navigate('/login');
    } else if (token && refereeName && (path === '/' || path === '/login' || path === '/register')) {
      navigate('/dashboard/default');
    }
  }, [cookies, navigate, location]);

  return children ? children : null;
};
