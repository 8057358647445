import React, { useEffect, useState } from 'react';
import { useCookiesContext } from 'utils/context/CookiesContext';
import menuItems from 'menu-items'; // 메뉴 항목을 가져옴
import MenuItem from './MenuItem'; // 방금 생성한 MenuItem 컴포넌트

const Navigation = () => {
  const { userInfo } = useCookiesContext();
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);

  // userAuth 값을 확인하고 상태를 설정
  const userAuth = parseInt(userInfo?.auth, 10) || 0;

  useEffect(() => {
    const filterMenuItems = (items) => {
      return items
        .filter((item) => {
          if (item.type === 'group') {
            item.children = filterMenuItems(item.children || []);
            return item.children.length > 0;
          }

          if (item.allowedAuth && !item.allowedAuth.includes(userAuth)) {
            return false;
          }

          return true;
        })
        .map((item) => ({ ...item }));
    };

    // 메뉴 필터링하여 상태 업데이트
    const newFilteredMenuItems = filterMenuItems(menuItems.items);
    setFilteredMenuItems(newFilteredMenuItems); // 상태 업데이트
  }, [userInfo, userAuth]); // userInfo가 변경될 때마다 메뉴를 갱신

  return (
    <>
      {filteredMenuItems.map((item) => (
        <MenuItem key={item.id} item={item} />
      ))}
    </>
  );
};

export default Navigation;
