import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { decodeToken, isTokenExpired } from '../jwtUtils'; // 디코드 함수 및 만료 확인 함수 임포트

const CookiesContext = createContext();

export const useCookiesContext = () => useContext(CookiesContext);

export const CookiesProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['jwtToken', 'refereeToken']);
  const [jwtToken, setJwtToken] = useState(cookies.jwtToken);
  const [userInfo, setUserInfo] = useState(null);
  const [refereeToken, setRefereeToken] = useState(cookies.refereeToken);

  // 쿠키 갱신 및 만료 확인을 위한 useEffect
  useEffect(() => {
    // 쿠키가 갱신될 때마다 jwtToken과 refereeToken을 설정
    setJwtToken(cookies.jwtToken);
    setRefereeToken(cookies.refereeToken);

    if (cookies.jwtToken) {
      // 토큰이 만료되었는지 확인
      if (isTokenExpired(cookies.jwtToken)) {
        logout(); // 토큰이 만료되었으면 자동 로그아웃
      } else {
        // 유효한 토큰이면 디코드하여 사용자 정보 설정
        const decoded = decodeToken(cookies.jwtToken);
        setUserInfo(decoded);
      }
    } else {
      setUserInfo(null); // 토큰이 없으면 사용자 정보를 null로 설정
    }
  }, [cookies.jwtToken, cookies.refereeToken]);

  // 로그인 함수: jwtToken을 설정하고 사용자 정보를 디코딩
  const login = (token) => {
    setCookie('jwtToken', token, { path: '/', maxAge: 86400 });
    setJwtToken(token);
    const decoded = decodeToken(token);
    setUserInfo(decoded);
  };

  // 심판 토큰 설정 함수
  const refereeCookie = (tokenData) => {
    setCookie('refereeToken', tokenData, { path: '/', maxAge: 86400 });
    setRefereeToken(tokenData);
  };

  // 로그아웃 함수: 쿠키에서 jwtToken과 refereeToken을 제거하고 상태 초기화
  const logout = () => {
    removeCookie('jwtToken', { path: '/' });
    removeCookie('refereeToken', { path: '/' });
    setJwtToken(null);
    setRefereeToken(null);
    setUserInfo(null); // 로그아웃 시 사용자 정보 초기화
  };

  return (
    <CookiesContext.Provider value={{ jwtToken, refereeToken, refereeCookie, userInfo, login, logout }}>{children}</CookiesContext.Provider>
  );
};
