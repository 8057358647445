// project import
import Profile from './Profile';

// assets

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

const MobileSection = () => {
  return (
    <>
      <Profile />
    </>
  );
};

export default MobileSection;
