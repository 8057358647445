import { lazy } from 'react';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Authmiddleware } from 'utils/route-guard/Authmiddleware';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - info page
const RulesInfo = Loadable(lazy(() => import('pages/info/RulesInfo')));

// render - Record page
const RecordPage = Loadable(lazy(() => import('pages/referee/record/RecordPage')));
const RecordPlayer = Loadable(lazy(() => import('pages/referee/record/RecordPlayer')));

// render - Players page
const PlayersPage = Loadable(lazy(() => import('pages/referee/players/PlayersPage')));
const PlayerDetail = Loadable(lazy(() => import('pages/referee/players/PlayerDetail')));

// render - Player CRUD page
const AddPage = Loadable(lazy(() => import('pages/referee/players/AddPage')));
const PlayerPage = Loadable(lazy(() => import('pages/referee/players/add/PlayerPage')));
const AddUploadPlayers = Loadable(lazy(() => import('pages/referee/players/add/AddUploadPlayers')));
const EditPlayerGames = Loadable(lazy(() => import('pages/referee/players/edit/EditPlayerGames')));

// render - Score page
const Scores = Loadable(lazy(() => import('pages/referee/scores/Scores')));

// render - Result page
const Result = Loadable(lazy(() => import('pages/manager/result/ResultPage')));

// render - Award page
const Award = Loadable(lazy(() => import('pages/manager/award/Award')));

// render - Rules page
const Rules = Loadable(lazy(() => import('pages/manager/rules/RulesForm')));

// render - RefereeInfo page
const RefereeInfo = Loadable(lazy(() => import('pages/manager/refereesInfo/RefereeInfo')));

// render - CompetitionSettingsPage page
const CompetitionSettingsPage = Loadable(lazy(() => import('pages/manager/competitionsettings/CompetitionSettingsPage')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <Authmiddleware>
      <MainLayout />
    </Authmiddleware>
  ),
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'info',
      element: <RulesInfo />
    },
    {
      path: 'record',
      children: [
        {
          path: 'search',
          element: <RecordPage />
        },
        {
          path: 'player/:playernumber',
          element: <RecordPlayer />
        }
      ]
    },
    {
      path: 'players',
      element: <PlayersPage />
    },
    {
      path: 'add',
      element: <AddPage />
    },
    {
      path: 'add-player/:competition_id',
      element: <PlayerPage />
    },

    {
      path: 'add-game/:competition_id/:playerNumber',
      element: <EditPlayerGames />
    },
    {
      path: 'add-upload-players/:competition_id',
      element: <AddUploadPlayers />
    },
    {
      path: 'player/detail/:playernumber',
      element: <PlayerDetail />
    },
    {
      path: 'scores',
      element: <Scores />
    },
    {
      path: 'award',
      element: <Award />
    },
    {
      path: 'result',
      element: <Result />
    },
    {
      path: 'rules',
      element: <Rules />
    },
    {
      path: 'referee-info',
      element: <RefereeInfo />
    },
    {
      path: 'competition-settings',
      element: <CompetitionSettingsPage />
    }
  ]
};

export default MainRoutes;
