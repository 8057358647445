// assets
import { ProfileOutlined } from '@ant-design/icons';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
// icons
const icons = {
  EmojiEventsIcon,
  ProfileOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const manager = {
  id: 'manager',
  title: '관리자 페이지',
  type: 'group',
  children: [
    {
      id: 'refereeInfo',
      title: '심판 관리',
      type: 'item',
      url: '/referee-info',
      icon: icons.ProfileOutlined,
      allowedAuth: [0, 1],
      breadcrumbs: false
    },
    {
      id: 'competitionsettingpage',
      title: '대회 설정',
      type: 'item',
      url: '/competition-settings',
      icon: icons.ProfileOutlined,
      allowedAuth: [0, 1],
      breadcrumbs: false
    },
    {
      id: 'rules',
      title: '경기 기준표',
      type: 'item',
      url: '/rules',
      icon: icons.ProfileOutlined,
      allowedAuth: [0, 1],
      breadcrumbs: false
    },
    {
      id: 'award',
      title: '상장 생성',
      type: 'item',
      url: '/award',
      icon: icons.EmojiEventsIcon,
      allowedAuth: [0, 1],
      breadcrumbs: false
    },
    {
      id: 'result',
      title: '결과',
      type: 'item',
      url: '/result',
      icon: icons.ProfileOutlined,
      allowedAuth: [0, 1],
      breadcrumbs: false
    }
  ]
};

export default manager;
