import React from 'react';
import { lazy } from 'react';
import { Authmiddleware } from 'utils/route-guard/Authmiddleware';
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout/index';

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthCompetitionRegister = Loadable(lazy(() => import('pages/authentication/CompetitionRegister')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <Authmiddleware>
      <MinimalLayout />
    </Authmiddleware>
  ),
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'competition-register',
      element: <AuthCompetitionRegister />
    }
  ]
};

export default LoginRoutes;
